import {
  BOX_RIGHT_POSITION,
  BOX_BOTTOM_POSITION,
  BOX_LEFT_POSITION,
  BOX_TOP_POSITION,
} from './constants'

export const LIMIT_OFFSET = 10

export function positionValidator(position) {
  const valid = [
    BOX_TOP_POSITION,
    BOX_BOTTOM_POSITION,
    BOX_LEFT_POSITION,
    BOX_RIGHT_POSITION,
  ]

  return valid.includes(position)
}

function center(x = 0, width = 0) {
  return x + width / 2
}

function getMinMaxFromElement(position = 0, width = 0, contentWidth = 0) {
  return [
    center(position, width) - contentWidth / 2,
    center(position, width) + contentWidth / 2,
  ]
}

function getPositionWithShift(position, size, contentSize, windowSize) {
  const [min, max] = getMinMaxFromElement(position, size, contentSize)

  if (max >= windowSize - LIMIT_OFFSET) {
    return windowSize - contentSize - LIMIT_OFFSET
  }

  if (min <= 0 + LIMIT_OFFSET) {
    return 0 + LIMIT_OFFSET
  }

  return min
}

// eslint-disable-next-line import/prefer-default-export
export function getPositionStyle({
  position,
  triggerBounding,
  contentBounding,
  windowWidth = 0,
  windowHeight = 0,
  visitedPositions = [],
  offset = 1,
} = {}) {
  const hasAlreadyVisitedPosition = visitedPositions.includes(position)

  switch (position) {
    case BOX_BOTTOM_POSITION: {
      const top = triggerBounding.bottom + offset

      if (
        triggerBounding.bottom + contentBounding.height >= windowHeight &&
        !hasAlreadyVisitedPosition
      ) {
        return getPositionStyle({
          position: BOX_TOP_POSITION,
          triggerBounding,
          contentBounding,
          windowWidth,
          windowHeight: windowWidth,
          visitedPositions: [...visitedPositions, position],
        })
      }

      const left = getPositionWithShift(
        triggerBounding.left,
        triggerBounding.width,
        contentBounding.width,
        windowWidth,
      )

      return { top, left, position }
    }

    case BOX_LEFT_POSITION: {
      const left = triggerBounding.left - (contentBounding?.width || 0) - offset

      if (
        triggerBounding.left - contentBounding.width < 0 &&
        !hasAlreadyVisitedPosition
      ) {
        return getPositionStyle({
          position: BOX_RIGHT_POSITION,
          triggerBounding,
          contentBounding,
          windowWidth,
          windowHeight: windowWidth,
          visitedPositions: [...visitedPositions, position],
        })
      }

      const top = getPositionWithShift(
        triggerBounding.top,
        triggerBounding.height,
        contentBounding.height,
        windowWidth,
      )

      return { top, left, position }
    }

    case BOX_RIGHT_POSITION: {
      const left = triggerBounding.right + offset

      if (
        triggerBounding.right + contentBounding.width > windowWidth &&
        !hasAlreadyVisitedPosition
      ) {
        return getPositionStyle({
          position: BOX_LEFT_POSITION,
          triggerBounding,
          contentBounding,
          windowWidth,
          windowsHeight: windowWidth,
          visitedPositions: [...visitedPositions, position],
        })
      }

      const top = getPositionWithShift(
        triggerBounding.top,
        triggerBounding.height,
        contentBounding.height,
        windowWidth,
      )

      return { top, left, position }
    }

    case BOX_TOP_POSITION: {
      const top = triggerBounding.top - (contentBounding.height || 0) - offset

      if (
        triggerBounding.top - contentBounding.height < 0 &&
        !hasAlreadyVisitedPosition
      ) {
        return getPositionStyle({
          position: BOX_BOTTOM_POSITION,
          triggerBounding,
          contentBounding,
          windowWidth,
          windowsHeight: windowWidth,
          visitedPositions: [...visitedPositions, position],
        })
      }

      const left = getPositionWithShift(
        triggerBounding.left,
        triggerBounding.width,
        contentBounding.width,
        windowWidth,
      )

      return { top, left, position }
    }

    default:
      return {}
  }
}
