import { DOG, CAT } from '@showcase/helpers/constants'

import { SINGLE_PET, TWO_PETS, INFINITE_PETS } from '../constants'
import * as steps from '../steps'
import * as oldSteps from '../steps.old'

import twoPetsSteps from './twoPets.steps'

const singlePetSteps = {
  old: [
    oldSteps.countStep,
    oldSteps.speciesStep,
    oldSteps.nameStep,
    oldSteps.genderStep,
    oldSteps.spayedStep,
    oldSteps.breedStep,
    oldSteps.ageStep,
    oldSteps.activityStep,
    oldSteps.fatnessStep,
    oldSteps.weightStep,
    oldSteps.repartitionStep,
    steps.loaderStep,
    steps.parentStep,
  ],
  new: [
    steps.countStep,
    steps.speciesStep,
    steps.breedStep,
    steps.genderStep,
    steps.ageStep,
    steps.activityStep,
    steps.fatnessStep,
    steps.weightFallbackStep,
    steps.weightStep,
    steps.diseaseStep,
    steps.sensibilityStep,
    steps.allergyStep,
    steps.appetiteStep,
    steps.reasonSteps,
    steps.repartitionStep,
    steps.wetfoodStep,
    steps.validateStep,
    steps.loaderStep,
    steps.parentStep,
  ],
}

const infinitePetSteps = {
  old: [
    oldSteps.countStep,
    oldSteps.speciesStep,
    oldSteps.nameStep,
    oldSteps.genderStep,
    oldSteps.spayedStep,
    oldSteps.breedStep,
    oldSteps.ageStep,
    oldSteps.activityStep,
    oldSteps.fatnessStep,
    oldSteps.weightStep,
    oldSteps.repartitionStep,
    oldSteps.validateStep,
    steps.loaderStep,
    steps.parentStep,
  ],
  new: [
    steps.countStep,
    steps.speciesStep,
    steps.breedStep,
    steps.genderStep,
    steps.ageStep,
    steps.activityStep,
    steps.fatnessStep,
    steps.weightFallbackStep,
    steps.weightStep,
    steps.diseaseStep,
    steps.sensibilityStep,
    steps.allergyStep,
    steps.appetiteStep,
    steps.reasonSteps,
    steps.repartitionStep,
    steps.wetfoodStep,
    steps.validateStep,
    steps.loaderStep,
    steps.parentStep,
  ],
}

export default {
  [SINGLE_PET]: {
    [DOG]: singlePetSteps,
    [CAT]: singlePetSteps,
  },
  [TWO_PETS]: twoPetsSteps,
  [INFINITE_PETS]: {
    [DOG]: infinitePetSteps,
    [CAT]: infinitePetSteps,
  },
}
