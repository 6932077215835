<template>
  <div :class="$style.wrapper">
    <div :class="$style.spacer" />

    <TwicImg v-if="imageUrl" :src="imageUrl" :class="$style.ingredientImage" />

    <div :class="$style.textContent" :style="{ backgroundColor }">
      <Typography :variant="TYPOGRAPHY_TYPES.bodyBold" :class="$style.title">
        {{ title }}
      </Typography>

      <Typography :variant="TYPOGRAPHY_TYPES.bodySmallRegular">
        {{ description }}
      </Typography>
    </div>
  </div>
</template>

<script>
import { Typography, TYPOGRAPHY_TYPES } from '@front/ui'

export default {
  components: { Typography },
  props: {
    title: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '#FFFFFF',
    },
    description: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    TYPOGRAPHY_TYPES: () => TYPOGRAPHY_TYPES,
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: $ds-spacing-md;
  position: relative;
}

.ingredientImage {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 68px;
  height: 68px;
  object-fit: cover;
  border-radius: 100%;
}

.spacer {
  height: 55px;
}

.textContent {
  flex: 1;
  width: 100%;
  padding: $ds-spacing-md;
  border-radius: 20px;
  background: $ds-color-white;
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: $ds-spacing-xs;
}
</style>
