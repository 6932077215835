/* eslint-disable no-param-reassign */
import { getPetSimulation } from '@front/common/endpoints'
import { petSchema } from '@front/common/types'
import { isArrayEmpty } from '@front/common/utils'

const initialState = () => ({
  drafts: [],
  pets: [],
})

export default {
  namespaced: true,
  state: initialState,
  getters: {
    get: (state) => state.pets,
    getDrafts: (state) => state.drafts,
    hasMultipleDrafts: (state) => state.drafts.length > 1,
    hasMultiplePets: (state) => state.pets.length > 1,
    areDraftsValid: (state) => !isArrayEmpty(state.drafts),
    getCurrent: (state, _getters, _rootState, rootGetters) => {
      const petIndex = rootGetters['profileBuilder/getPetIndex'] || 0

      return state.pets[petIndex] || {}
    },
    getCurrentDraft: (state, _getters, _rootState, rootGetters) => {
      const draftIndex = rootGetters['profileBuilder/getPetIndex'] || 0

      return state.drafts[draftIndex] || {}
    },
  },
  mutations: {
    reset(state) {
      Object.assign(state, initialState())
    },
    addDraft(state, draft) {
      state.drafts.push(draft)
    },
    setDrafts(state, drafts) {
      state.drafts = drafts
      state.pets = []
    },
    setPets(state, pets) {
      state.pets = pets
    },
    updatePet(state, updatedPet) {
      state.pets = state.pets.map((pet) => {
        return pet.id === updatedPet.id ? updatedPet : pet
      })
    },
    updateDraft(state, updatedDraft) {
      state.drafts = state.drafts.map((draft) =>
        draft.id === updatedDraft.id ||
        draft.resourceId === updatedDraft.resourceId
          ? updatedDraft
          : draft,
      )
    },
  },
  actions: {
    async validateDrafts({ commit, state: argState }) {
      const newValidPets = []

      // eslint-disable-next-line no-restricted-syntax
      for (const draft of argState.drafts) {
        try {
          petSchema.validateSync(draft)
          // eslint-disable-next-line no-await-in-loop
          const pet = await getPetSimulation(draft)

          newValidPets.push(pet)
        } catch (error) {
          console.error('coucou', draft, error)
        }
      }

      commit('setPets', newValidPets)
    },
  },
}
