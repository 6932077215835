import { CAT, DOG } from '@showcase/helpers/constants'

import * as steps from '../steps'
import * as oldSteps from '../steps.old'

/**
 * @description adds a `restartStep` parameter to `repartition` to add second pet & reset steps
 */

const commonSteps = {
  old: [
    oldSteps.countStep,
    oldSteps.speciesStep,
    oldSteps.nameStep,
    oldSteps.genderStep,
    oldSteps.spayedStep,
    oldSteps.breedStep,
    oldSteps.ageStep,
    oldSteps.activityStep,
    oldSteps.fatnessStep,
    oldSteps.weightStep,
    {
      ...oldSteps.repartitionStep,
      restartStep: true,
    },
    steps.loaderStep,
    steps.parentStep,
  ],
  new: [
    steps.countStep,
    steps.speciesStep,
    steps.breedStep,
    steps.genderStep,
    steps.ageStep,
    steps.activityStep,
    steps.fatnessStep,
    steps.weightFallbackStep,
    steps.weightStep,
    steps.diseaseStep,
    steps.sensibilityStep,
    steps.allergyStep,
    steps.appetiteStep,
    steps.reasonSteps,
    {
      ...steps.repartitionStep,
      restartStep: true,
    },
    steps.wetfoodStep,
    steps.validateStep,
    steps.loaderStep,
    steps.parentStep,
  ],
}

export default {
  [DOG]: commonSteps,
  [CAT]: commonSteps,
}
