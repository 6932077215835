import { DOG, MIXED_DIET } from '@front/common/constants'
import { isUnknownedBreed } from '@front/common/utils'

import {
  SHOWCASE_ROUTE_PROFILE_BUILDER_COUNT,
  SHOWCASE_ROUTE_PROFILE_BUILDER_SPECIES,
  SHOWCASE_ROUTE_PROFILE_BUILDER_GENDER,
  SHOWCASE_ROUTE_PROFILE_BUILDER_BREED,
  SHOWCASE_ROUTE_PROFILE_BUILDER_AGE,
  SHOWCASE_ROUTE_PROFILE_BUILDER_ACTIVITY,
  SHOWCASE_ROUTE_PROFILE_BUILDER_FATNESS,
  SHOWCASE_ROUTE_PROFILE_BUILDER_WEIGHT,
  SHOWCASE_ROUTE_PROFILE_BUILDER_REPARTITION,
  SHOWCASE_ROUTE_PROFILE_BUILDER_VALIDATE,
  SHOWCASE_ROUTE_PROFILE_BUILDER_PARENT,
  SHOWCASE_ROUTE_PROFILE_BUILDER_LOADER,
  SHOWCASE_ROUTE_PROFILE_BUILDER_WEIGHT_FALLBACK,
  SHOWCASE_ROUTE_PROFILE_BUILDER_DISEASE,
  SHOWCASE_ROUTE_PROFILE_BUILDER_SENSIBILITY,
  SHOWCASE_ROUTE_PROFILE_BUILDER_ALLERGY,
  SHOWCASE_ROUTE_PROFILE_BUILDER_APPETITE,
  SHOWCASE_ROUTE_PROFILE_BUILDER_REASON,
  SHOWCASE_ROUTE_PROFILE_BUILDER_WETFOOD,
} from '@showcase/core/routes-names'

export const countStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_COUNT,
}

export const speciesStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_SPECIES,
}

export const genderStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_GENDER,
}

export const breedStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_BREED,
}

export const ageStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_AGE,
}

export const activityStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_ACTIVITY,
}

export const fatnessStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_FATNESS,
}

export const weightStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_WEIGHT,
}

export const repartitionStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_REPARTITION,
  isDisplayed(window, redirectUrl) {
    return !redirectUrl
  },
}

export const diseaseStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_DISEASE,
}

export const sensibilityStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_SENSIBILITY,
}

export const allergyStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_ALLERGY,
}

export const appetiteStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_APPETITE,
}

export const reasonSteps = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_REASON,
}

export const weightFallbackStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_WEIGHT_FALLBACK,
  isDisplayed(_, __, { breeds, species }) {
    return breeds?.some(isUnknownedBreed) && species === DOG
  },
}

export const wetfoodStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_WETFOOD,
  isDisplayed(_, __, { diet }) {
    return diet === MIXED_DIET
  },
}

export const validateStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_VALIDATE,
}

export const parentStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_PARENT,
}

export const loaderStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_LOADER,
}
