export const CAT = 'cat'
export const DOG = 'dog'
export const MIX = 'mix'

export const BABY = 'baby'
export const ADULT = 'adult'

export const DOG_FISH_IMAGE_ID =
  'https://japhy-assets.twic.pics/download?id=1E4BPmdpieI_cBYMl4UXjl3BMrUGfoKzi'
export const DOG_CHICKEN_IMAGE_ID =
  'mabrouk/img/profile-builder/dog-chicken-pack_hmcoku.png'
export const CAT_FISH_IMAGE_ID =
  'https://japhy-assets.twic.pics/download?id=1E5zHpazh4D14TFOEqQU5dEljGXNycBHU'
export const CAT_CHICKEN_IMAGE_ID =
  'mabrouk/img/profile-builder/cat-chicken-pack_mmrts0.png'
export const CAT_WETFOOD_IMAGE_ID =
  'mabrouk/img/profile-builder/cat-fish-wetFood_mbnkdy.png'
export const DOG_WETFOOD_IMAGE_ID =
  'mabrouk/img/profile-builder/Conserve_chien_transparent_ombre_rcc3tw.png'
export const CAT_BEEF_IMAGE_ID =
  'mabrouk/img/profile-builder/cat-beef-pack_z7afw2.png'
export const DOG_BEEF_IMAGE_ID =
  'mabrouk/img/profile-builder/dog-beef-pack_szrujx.png'

export const RECIPE_TRANSLATION_TYPE_TITLE = 'title'

export const UPSELL = 'upsell'
export const KIBBLE = 'kibble'
export const EXTRA = 'extra'
export const WETFOOD = 'wetFood'
export const CUSTOM_FOOD = 'custom_food'
export const GIFT = 'gift'

export const WETFOOD_RATIO = {
  zero: 'zero',
  low: 'low',
  medium: 'medium',
  high: 'high',
}

export const wetfoodRatiosTranslations = {
  [WETFOOD_RATIO.low]: 'Peu souvent',
  [WETFOOD_RATIO.medium]: 'De temps en temps',
  [WETFOOD_RATIO.high]: 'Tous les jours',
}

export const WETFOOD_RATIO_VALUES = {
  [WETFOOD_RATIO.zero]: 0,
  [WETFOOD_RATIO.low]: 25,
  [WETFOOD_RATIO.medium]: 50,
  [WETFOOD_RATIO.high]: 75,
}

export const RECOMMANDED_RATIO = WETFOOD_RATIO.low

export const CORPULENCE_LEVELS = {
  veryThin: 1,
  thin: 2,
  slim: 3,
  slight: 4,
  ideal: 5,
  idealPlus: 6,
  thick: 7,
  thickPlus: 8,
  veryThick: 9,
} as const

export const ACTIVITY_LEVELS = {
  inactive: 1,
  lazy: 2,
  active: 3,
  hyperActive: 4,
  beast: 5,
} as const

export const wordingForFrequencies = {
  30: 'tous les mois',
  60: 'tous les 2 mois',
  90: 'tous les 3 mois',
}

export const TRIAL_PERIOD = 15
export const MONTHLY_PERIOD = 30
export const BIMONTHLY_PERIOD = 60
export const MONTHLY_TRIAL_PERIOD = 0 // @TODO: update frequency

export const MIXED_DIET = 'mixed'
export const KIBBLE_DIET = 'kibble'

export const ACTIVE = 'active'
export const INACTIVE = 'inactive'

export const statusTranslations = {
  [ACTIVE]: 'Actif',
  [INACTIVE]: 'Inactif',
}

export const GENDER_MALE = 'male'
export const GENDER_FEMALE = 'female'

export const CONTACT_PHONE_NUMBER = '0970254404'

export const MAX_WETFOOD_SELECTION_AMOUNT = 2

export const ORDER_STATUS_AWAITING_FULFILLMENT = 'awaiting fulfillment'
export const ORDER_STATUS_COMPLETED = 'completed'
export const ORDER_STATUS_SHIPPED = 'shipped'
export const ORDER_TYPE_RENEWAL = 'renewal'

export const SUCCESS = 'success'
export const FAILURE = 'failure'
export const WARNING = 'warning'
export const LOADING = 'loading'
export const IDLE = 'idle'

export const CONTEXT_CONDITION_OFFERS_ADD_PET = 'add_pet'
export const CONTEXT_CONDITION_OFFERS_OFFER = 'offer'
export const CONTEXT_CONDITION_OFFERS_OTHER = 'other'

export const AVAILABLE_COUNTRIES = [
  'FR',
  'DE',
  'ES',
  'BE',
  'IT',
  'LU',
  'NL',
  'PT',
]

export const ISO_LOCALE = 'en-CA' // closest locale to ISO date

// 2023/Nouvelle%20home/Ingr%C3%A9dients/betterave_c6avoz.png

export const INGREDIENTS_IMG_IDS = {
  cucumber:
    'https://japhy-assets.twic.pics/download?id=1RTaKKgU4L-Vt-CKtQA44ni76Qd2n_Pml',
  mint: 'https://japhy-assets.twic.pics/download?id=1Rdcjek5cqYUBQiKe2Os6JVqriznEYWit',
  peas: 'https://japhy-assets.twic.pics/download?id=1GEaMUAZb1OR76J38S1YfMvpbul3fEbUH',
  meat_juice:
    'https://japhy-assets.twic.pics/download?id=1RemgQJ9HB1FipyPhGTra2nvzapxK13nN',
  prebiotics:
    'https://japhy-assets.twic.pics/download?id=1RnN0rNDYn8U4gyAwpQu3h8myc2Z-zSAm',
  wood: 'https://japhy-assets.twic.pics/download?id=1R_KKOkfGcHRu1EjiQwOm32CVnPDejgmM',
  tuna: 'https://japhy-assets.twic.pics/download?id=1RfAcrwK8NVzYO_pfiTDHTo1vsrgsPjA9',
  apples:
    'https://japhy-assets.twic.pics/download?id=1RmGufx6IhtphkVBtq0fQTyyMASOuVzIP',
  linseed_oil:
    'https://japhy-assets.twic.pics/download?id=1GbfOv4SzX9F0Q4KjA8JCPCSrcvmviTFG',
  poultry:
    'https://japhy-assets.twic.pics/download?id=1H0MxuauOpFkKYMLy3QvK-YuTbD-Cztrt',
  argile: null,
  tapioca:
    'https://japhy-assets.twic.pics/download?id=1LXjlB1ZiTYllLoHtD0y4Ibhzvf3rilNG',
  beef: 'https://japhy-assets.twic.pics/download?id=1GvTHJoE-UliLuRnK1mkQwqE3I7HC0X2h',
  beer_yeast:
    'https://japhy-assets.twic.pics/download?id=1GKJwQCQiharmVIcugV0yF5VLCo6vR7y7',
  seaweed:
    'https://japhy-assets.twic.pics/download?id=1L_bO2dIOFFiWXo7paKq0KYCI7zGx7ubs',
  pork: 'https://japhy-assets.twic.pics/download?id=1Le75IjjTWvA4hIHttdfDXoi9uuC2ioYz',
  carrot:
    'https://japhy-assets.twic.pics/download?id=1GCIWkCzneuwmLCG9ezyBRQgEF_BNElhN',
  linseeds:
    'https://japhy-assets.twic.pics/download?id=1Gwcnn4qzQaw3Yxm_AU6db92TSNWlqwhp',
  chicken:
    'https://japhy-assets.twic.pics/download?id=1H0MxuauOpFkKYMLy3QvK-YuTbD-Cztrt',
  dehydrated_apples: null,
  dehydrated_carrots:
    'https://japhy-assets.twic.pics/download?id=1GCIWkCzneuwmLCG9ezyBRQgEF_BNElhN',
  egg_powder: null,
  fish: 'https://japhy-assets.twic.pics/download?id=1H1z-3oMyVm-CW5q_GPOHBsRnfrWV7VVO',
  fish_oil:
    'https://japhy-assets.twic.pics/download?id=1GbfOv4SzX9F0Q4KjA8JCPCSrcvmviTFG',
  peels: null,
  plants: null,
  salmon:
    'https://japhy-assets.twic.pics/download?id=1H1z-3oMyVm-CW5q_GPOHBsRnfrWV7VVO',
  spirulina:
    'https://japhy-assets.twic.pics/download?id=1LYSk8OReuK1T0r_uVz_BZQkXVOqLN9NR',
  vegetables: null,
  white_rice:
    'https://japhy-assets.twic.pics/download?id=1GJwWVnfYDNgw7Ew1Vom7nVbiYldlCw4o',
  yeast: null,
  zucchini:
    'https://japhy-assets.twic.pics/download?id=1L_HcePHWeg3O4Grx08zqCcqWKhxH1QA3',
  brown_rice:
    'https://japhy-assets.twic.pics/download?id=1H4mmv41yL97g2-bhYY_4HcRi4yIy0TTc',
  yellow_peas:
    'https://japhy-assets.twic.pics/download?id=1GEaMUAZb1OR76J38S1YfMvpbul3fEbUH',
}

export const PET_SIZE_SMALL = 1
export const PET_SIZE_MEDIUM = 2
export const PET_SIZE_LARGE = 3

export const PET_SIZES = {
  small: {
    id: 1,
    name: 'small',
  },
  medium: {
    id: 2,
    name: 'medium',
  },
  large: {
    id: 3,
    name: 'large',
  },
}

export const API_REFERER = 'api'

export const UNKNOWN_BREEDS = ['undefined', 'european', 'alley_cat']

export const petWordings = {
  'chiot petit race': {
    firstWording: 'Un taux élevé en protéines animales de haute qualité (82%)',
    secondWording: 'Des prébiotiques (FOS & MOS) pour une digestion saine',
    thirdWording: 'Niveau optimal en vitamines A et E pour une bonne énergie',
  },
  'chiot grande race': {
    firstWording: 'Un taux élevé en protéines animales de haute qualité (82%)',
    secondWording: 'Des prébiotiques (FOS & MOS) pour une digestion saine',
    thirdWording: 'Niveau optimal en vitamines A et E pour une bonne énergie',
  },
  'chien adulte': {
    firstWording: '80% de protéines animales de haute qualité',
    secondWording:
      'Une teneur en fibres équilibrée pour une bonne santé digestive',
    thirdWording:
      'Des EPA et DHA pour favoriser la santé de la peau et du pelage',
  },
  'chien adulte castré': {
    firstWording:
      '80% de protéines animales pour préserver la masse musculaire existante',
    secondWording:
      'Une recette allégée en matières grasses (12%) pour éviter la prise de poids',
    thirdWording:
      'Avec des fibres nutritives (pulpe de betterave) pour favoriser la sensation de satiété',
  },
  'chien adulte stérilisé': {
    firstWording:
      '80% de protéines animales pour préserver la masse musculaire existante',
    secondWording:
      'Une recette allégée en matières grasses (12%) pour éviter la prise de poids',
    thirdWording:
      'Avec des fibres nutritives (pulpe de betterave) pour favoriser la sensation de satiété',
  },
  'chien senior': {
    firstWording: '80% de protéines animales de haute qualité',
    secondWording: 'Des acides gras oméga-3 pour protéger les articulations',
    thirdWording:
      'Une teneur en fibres équilibrée pour une bonne santé digestive',
  },
  'chien senior 6+': {
    firstWording: '80% de protéines animales de haute qualité',
    secondWording: 'Des acides gras oméga-3 pour protéger les articulations',
    thirdWording:
      'Une teneur en fibres équilibrée pour une bonne santé digestive',
  },
  chaton: {
    firstWording: 'Un taux élevé en protéines animales de haute qualité (87%)',
    secondWording: 'Des prébiotiques (FOS) pour une digestion saine',
    thirdWording: 'Niveau optimal en vitamines A et E pour une bonne énergie',
  },
  'chat adulte': {
    firstWording: '85% de protéines animales de haute qualité',
    secondWording:
      'Une teneur en fibres équilibrée pour une bonne santé digestive',
    thirdWording:
      'Des EPA et DHA pour favoriser la santé de la peau et du pelage',
  },
  'chat adulte stérilisé': {
    firstWording:
      '85% de protéines animales pour préserver la masse musculaire existante',
    secondWording:
      'Une teneur modérée en matières grasses (10%) pour limiter la prise de poids excessive',
    thirdWording:
      'Un niveau de phosphore adapté pour aider à maintenir la bonne santé rénale',
  },
  'chat senior': {
    firstWording: '85% de protéines animales de haute qualité',
    secondWording:
      'Une teneur modérée en matières grasses (10%) pour limiter la prise de poids excessive',
    thirdWording:
      'Un niveau de phosphore adapté pour aider à maintenir la bonne santé rénale',
  },
  'chat senior 6+': {
    firstWording: '85% de protéines animales de haute qualité',
    secondWording:
      'Une teneur modérée en matières grasses (10%) pour limiter la prise de poids excessive',
    thirdWording:
      'Un niveau de phosphore adapté pour aider à maintenir la bonne santé rénale',
  },
}

export const PENDING = 'pending'
export const COMPLETED = 'completed'
export const SHIPPED = 'shipped'
export const CANCELLED = 'cancelled'
export const DECLINED = 'declined'
export const REFUNDED = 'refunded'
export const FAILED = 'failed'
export const BLOCKED = 'blocked'
export const AWAITING_PAYMENT = 'awaiting payment'
export const AWAITING_FULFILLMENT = 'awaiting fulfillment'
export const AWAITING_SHIPMENT = 'awaiting shipment'
export const AWAITING_PICKUP = 'awaiting pickup'
export const AVAILABLE_AT_PICKUP_POINT = 'available_at_pickup_point'
export const PARTIALLY_SHIPPED = 'partially shipped'
export const CANCELLATION_REQUESTED = 'cancellation requested'

export const INCOMING_STATE = 'incoming'
export const INFO_STATE = 'info'
export const WARNING_STATE = 'warning'
export const DANGER_STATE = 'danger'
export const SUCCESS_STATE = 'success'

export const STATUS_LABELS = {
  [PENDING]: 'A venir',
  [AWAITING_PAYMENT]: 'En attente de paiement',
  [AWAITING_FULFILLMENT]: 'En cours de préparation',
  [AWAITING_SHIPMENT]: 'Prête à être expédiée',
  [AWAITING_PICKUP]: 'En attente de retrait',
  [PARTIALLY_SHIPPED]: 'Expédiée partiellement',
  [AVAILABLE_AT_PICKUP_POINT]: 'Disponible en point relais',
  [COMPLETED]: 'Livrée',
  [SHIPPED]: 'Expédiée',
  [CANCELLED]: 'Annulée',
  [DECLINED]: 'Refusée',
  [REFUNDED]: 'Remboursée',
  [FAILED]: 'Échouée',
  [BLOCKED]: 'Bloquée',
  [CANCELLATION_REQUESTED]: 'Annulation demandée',
}

export const ONE_SHOT = 'oneShot'
export const TRIAL = 'trial'
export const RENEWAL = 'renewal'

export const ORDER_TYPE = {
  [ONE_SHOT]: 'One Shot',
  [TRIAL]: 'Trial',
  [RENEWAL]: 'Renewal',
}

export const KIBBLE_BAG = 'kibble bag'
export const TREATS = 'treats'
export const TREATS_BAG = 'treats bag'
export const CUP = 'cup'
export const GOODIE = 'goodie'
export const FLYER = 'flyer'
export const WETFOOD_LOWERCASE = 'wetfood'
export const POUNCH = 'pouch'
export const CONSUMABLE = 'consumable'
export const MISCELLANEOUS = 'miscellaneous'
export const BOX = 'box'
export const BUNDLE = 'bundle'

export const ARTICLE_TYPE = {
  [KIBBLE]: 'Croquette',
  [KIBBLE_BAG]: 'Sac de croquette',
  [TREATS]: 'Friandises',
  [TREATS_BAG]: 'Sac de friandises',
  [CUP]: 'Cup',
  [GOODIE]: 'Goodie',
  [FLYER]: 'Flyer',
  [WETFOOD_LOWERCASE]: 'Terrines',
  [POUNCH]: 'Poche',
  [CONSUMABLE]: 'Consommable',
  [MISCELLANEOUS]: 'Divers',
  [BOX]: 'Boîte',
  [BUNDLE]: 'Bundle',
}

export const STATUSES_STATES = {
  [INCOMING_STATE]: [],
  [INFO_STATE]: [SHIPPED, PARTIALLY_SHIPPED],
  [WARNING_STATE]: [
    AWAITING_PAYMENT,
    AWAITING_FULFILLMENT,
    AWAITING_SHIPMENT,
    AWAITING_PICKUP,
    CANCELLATION_REQUESTED,
  ],
  [DANGER_STATE]: [FAILED, CANCELLED, DECLINED, BLOCKED],
  [SUCCESS_STATE]: [COMPLETED, REFUNDED, AVAILABLE_AT_PICKUP_POINT],
}

export const EFFECT_TYPE_CUMULATIVE_DISCOUNT = 'cumulative_discount'
export const EFFECT_TYPE_PRODUCT = 'product'
export const EFFECT_TYPE_OPTION = 'option'
export const EFFECT_TYPE_DISCOUNT = 'discount'
export const EFFECT_TYPE_RENEWAL_DISCOUNT = 'renewal_discount'
export const EFFECT_TYPE_FLAG = 'flag'

export const EFFECTS_TYPE = {
  [EFFECT_TYPE_PRODUCT]: 'Produit',
  [EFFECT_TYPE_OPTION]: 'Option',
  [EFFECT_TYPE_FLAG]: 'Flag',
  [EFFECT_TYPE_DISCOUNT]: 'Avoir',
  [EFFECT_TYPE_RENEWAL_DISCOUNT]: 'Avoir renouvellement',
  [EFFECT_TYPE_CUMULATIVE_DISCOUNT]: 'Avoir cumulatif',
}

export const GROUPED_ORDERS_DAYS_INTERVAL = 7

export const SALMON_OIL_PRODUCT_ID = 11

export const GIFT_FALLBACK_IMG_URL =
  'https://japhy-assets.twic.pics/download?id=1usfNr7A4ZV8VBY1LbMjltNUbG8to9Y-h'

export const RESUBSCRIBE_CONTEXT = 'resubscribe'
export const CUSTOMER_STATUS_SUBSCRIBED = 'subscribed'
export const CUSTOMER_STATUS_TRIAL_PERIOD = 'trial_period'
export const CUSTOMER_STATUS_UNSUBSCRIBED = 'unsubscribed'
export const CUSTOMER_STATUS_UNPAID = 'unpaid'

export const DISCOUNT_STATUSES = {
  DELETED: 'deleted',
  USED: 'used',
  UNUSED: 'never_used',
  ONGOING: 'ongoing',
}

export const CUSTOMER_OWN_SPONSORSHIP_DISCOUNT = 30
export const SPONSORED_CUSTOMERS_DISCOUNTS = [20, 10, 10]

export const TABLET_MIN_WIDTH_BREAKPOINT = 1264
export const UNDEFINED_CAT_BREED_ID = 320
export const UNDEFINED_DOG_BREED_ID = 319
