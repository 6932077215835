<template>
  <ul
    :class="[
      $style.wrapper,
      $style[theme],
      { [$style.horizontal]: horizontal },
    ]"
    v-bind="$attrs"
  >
    <slot />
  </ul>
</template>

<script>
import { defineComponent } from 'vue-demi'

export default defineComponent({
  provide() {
    return {
      listTheme: this.theme,
      horizontal: this.horizontal,
    }
  },
  props: {
    theme: {
      type: String,
      default: 'default',
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  list-style: none;

  &.horizontal {
    flex-direction: row;
  }

  &.secondary {
    box-shadow: inset 0 0 0 1px $ds-color-black-background;
    border-radius: $ds-radius-xs;
    overflow: hidden;
  }
}
</style>
