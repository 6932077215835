<script>
import { defineComponent } from 'vue-demi'

import Typography from './Typography'
import TYPOGRAPHY_TYPES from './typographyTypes'

export default defineComponent({
  props: {
    initialTabIndex: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'box',
      validator: (value) => {
        return ['box', 'underline'].includes(value)
      },
    },
    alignment: {
      type: String,
      default: 'center',
      validator: (value) => {
        return ['start', 'center'].includes(value)
      },
    },
  },
  data() {
    return {
      activeTabIndex: this.initialTabIndex,
      slideDirection: '',
    }
  },
  computed: {
    activeTabContent() {
      return this.$slots.content?.filter((miel, index) => {
        return index === this.activeTabIndex
      })
    },
  },
  methods: {
    handleClick(index) {
      this.slideDirection = index > this.activeTabIndex ? 'right' : 'left'
      this.activeTabIndex = index
      this.$emit('activeTabIndex', this.activeTabIndex)
    },
  },
  render() {
    return (
      <div>
        <header class={this.$style.cardHeader}>
          <ul
            class={
              this.type === 'box'
                ? this.$style.tabHeadsBox
                : [this.$style.tabHeadsUnderline, this.$style[this.alignment]]
            }
          >
            {this.$slots.header?.map((item, index) => {
              return (
                <Typography
                  variant={TYPOGRAPHY_TYPES.bodyBold}
                  tag="li"
                  key={index}
                  class={[
                    this.type === 'box'
                      ? this.$style.tabHeadBox
                      : this.$style.tabHeadUnderline,
                    {
                      [this.$style.tabHeadActiveBox]:
                        this.activeTabIndex === index && this.type === 'box',
                      [this.$style.tabHeadActiveUnderline]:
                        this.activeTabIndex === index &&
                        this.type === 'underline',
                    },
                  ]}
                  onClick={() => this.handleClick(index)}
                >
                  {item}
                </Typography>
              )
            })}
          </ul>

          {this.$slots.rightContent}
        </header>

        <div class={this.$style.content}>{this.activeTabContent}</div>
      </div>
    )
  },
})
</script>

<style lang="scss" module>
$duration: 0.4s;

.cardHeader {
  padding: $ds-spacing-2xs;
  background: $ds-color-black-background;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  border-radius: $ds-radius-lg;
  justify-content: center;
  align-items: center;

  color: $ds-color-black;

  text-align: center;
  z-index: 0;
}

.tabHeadsBox {
  display: inline-flex;
  overflow: hidden;
  // min-width: 335px;
  width: 100%;
  border-radius: $ds-radius-lg;

  align-items: stretch;
  justify-content: center;

  list-style: none;

  background-color: $ds-color-black-background;
}

.tabHeadBox {
  position: relative;

  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  cursor: pointer;
  background-color: $ds-color-black-background;
  color: $ds-color-black;

  &:first-child {
    border-radius: $ds-radius-lg;
  }

  &:last-child {
    border: 0;
  }
}

.tabHeadsUnderline {
  display: inline-flex;
  overflow: hidden;
  min-width: 335px;
  width: 100%;

  list-style: none;

  &.start {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }
}

.tabHeadUnderline {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: $ds-spacing-lg;
  padding: $ds-spacing-xs;
  text-align: center;
  cursor: pointer;
  color: $ds-color-black-label;
}

.tabHeadActiveBox {
  background-color: $ds-color-white;

  &:first-child {
    border-radius: $ds-radius-lg;
  }

  &:last-child {
    border-radius: $ds-radius-lg;
  }
}

.tabHeadActiveUnderline {
  color: $ds-color-black;
  padding: $ds-spacing-xs;
  border-bottom: 2px solid $ds-color-black;
}

.wrapperList {
  position: relative;

  overflow: hidden;
}
</style>
