<template>
  <Modal :name="name">
    <div>
      <img
        src="@front/common/assets/flash-bg-blue.svg"
        width="50"
        height="50"
      />

      <Typography :variant="TYPOGRAPHY_TYPES.h5" tag="h5" :class="$style.title">
        {{ $i18n('Des besoins journaliers selon le profil de votre animal') }}
      </Typography>

      <Typography tag="p">
        {{
          $i18n(
            "Japhy recommande un menu composé de {kibblesPercentage}% de croquettes et de {wetfoodPercentage}% de terrines. Vous pouvez modifier cette répartition à l'étape suivante.",
            { kibblesPercentage, wetfoodPercentage },
          )
        }}
      </Typography>

      <Accordeon :class="$style.accordeon">
        <template #trigger>
          <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
            {{ $i18n('Les bienfaits de la bi-nutrition') }}
          </Typography>
        </template>

        <template #content>
          <Typography>
            <p>
              {{
                $i18n(
                  'Mixer quotidiennement croquettes et alimentation humide (pâtées, terrines, émincés en sauce…) peut offrir à votre animal de nombreux bienfaits sur sa santé comme :',
                )
              }}
            </p>

            <ul :class="$style.list">
              <li :class="$style.listItem">
                {{ $i18n('la maîtrise du poids') }}
              </li>

              <li :class="$style.listItem">
                {{ $i18n('la prévention des troubles urinaires') }}
              </li>

              <li :class="$style.listItem">
                {{ $i18n('apporter de la nouveauté (textures, goûts…)') }}
              </li>
            </ul>
          </Typography>
        </template>
      </Accordeon>
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from 'vue-demi'

import { Accordeon, Typography, TYPOGRAPHY_TYPES, Modal } from '@front/ui'

export default defineComponent({
  components: { Modal, Typography, Accordeon },
  props: {
    name: {
      type: String,
      default: 'needsModal',
    },
    wetfoodPercentage: {
      type: Number,
      default: 0,
    },
    kibblesPercentage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    TYPOGRAPHY_TYPES: () => TYPOGRAPHY_TYPES,
  },
})
</script>

<style lang="scss" module>
.title {
  margin: $ds-spacing-md 0px;
}

.accordeon {
  margin-top: $ds-spacing-md;
}

.list {
  margin-top: $ds-spacing-xs;
}

.listItem {
  margin-left: $ds-spacing-md;
}
</style>
