export const SHOWCASE_ROUTE_HOMEPAGE = 'home'
export const SHOWCASE_ROUTE_ELEMENTS = 'elements'
export const SHOWCASE_ROUTE_ASSISTANT = 'assistant'
export const SHOWCASE_ROUTE_CATS = 'cats'
export const SHOWCASE_ROUTE_UNSUBSCRIBE = 'desabonnement'
export const SHOWCASE_ROUTE_HOW_IT_WORKS = 'howItWorks'
export const SHOWCASE_ROUTE_OUR_RECIPES = 'ourRecipes'
export const SHOWCASE_ROUTE_MISSION = 'mission'
export const SHOWCASE_ROUTE_POLICIES = 'policies'
export const SHOWCASE_ROUTE_PRIVACY = 'privacy'
export const SHOWCASE_ROUTE_SHOWMIUM = 'shopmium'
export const SHOWCASE_ROUTE_SHOWROOM_PRIVE = 'showroomprive'
export const SHOWCASE_ROUTE_TEMPLATE = 'template'
export const SHOWCASE_ROUTE_TERMS_AND_CONDITIONS = 'termsAndConditions'
export const SHOWCASE_ROUTE_VEEPEE = 'veepee'
export const SHOWCASE_ROUTE_CONFIRM = 'confirm'
export const SHOWCASE_ROUTE_DELIVERY = 'delivery'
export const SHOWCASE_ROUTE_PRODUCT = 'product'
export const SHOWCASE_ROUTE_PRODUCTS = 'products'
export const SHOWCASE_ROUTE_NEEDS = 'needs'
export const SHOWCASE_ROUTE_MENU = 'menu'
export const SHOWCASE_ROUTE_EDITION_MENU = 'editionMenu'
export const SHOWCASE_ROUTE_DISCOVERY_OFFER = 'discoveryOffer'
export const SHOWCASE_ROUTE_SUMMARY = 'summary'
export const SHOWCASE_ROUTE_RESUBSCRIBE_SUMMARY = 'resubscribeSummary'
export const SHOWCASE_ROUTE_CONFIRMATION_PAYMENT = 'confirmationPayment'

export const SHOWCASE_ROUTE_PROFILE_BUILDER = 'profileBuilder'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_ERROR = 'profileBuilderError'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_COUNT = 'count'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_VALIDATE = 'validate'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_ACTIVITY = 'activity'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_AGE = 'age'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_ALLERGIES = 'allergies'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_BREED = 'breed'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_FATNESS = 'fatness'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_REPARTITION = 'repartition'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_GENDER = 'gender'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_SPAYED = 'spayed'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_SPECIES = 'species'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_WEIGHT = 'weight'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_PARENT = 'parent'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_DISEASE = 'disease'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_SENSIBILITY = 'sensibility'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_ALLERGY = 'allergy'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_APPETITE = 'appetite'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_REASON = 'reason'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_LOADER = 'loader'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_WEIGHT_FALLBACK = 'weightFallback'
export const SHOWCASE_ROUTE_PROFILE_BUILDER_WETFOOD = 'wetfood'

// old steps
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_COUNT = 'old-count'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_VALIDATE = 'old-validate'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_ACTIVITY = 'old-activity'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_AGE = 'old-age'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_ALLERGIES = 'old-allergies'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_BREED = 'old-breed'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_FATNESS = 'old-fatness'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_NAME = 'old-name'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_REPARTITION = 'old-repartition'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_GENDER = 'old-gender'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_SPAYED = 'old-spayed'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_SPECIES = 'old-species'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_WEIGHT = 'old-weight'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_PARENT = 'old-parent'
export const SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_LOADER = 'old-loader'
export const SHOWCASE_ROUTE_OLD_CONFIRMATION_PAYMENT = 'old-confirmationPayment'
