<template>
  <ImageOverlay :class="$style.wrapper" :src="url">
    <template #content>
      <div :class="[$style.contentWrapper, $style[defaultFadeAppearance]]">
        <div :class="$style.content">
          <Typography :variant="TYPOGRAPHY_TYPES.h5" :class="$style.date">
            <slot name="date" />
          </Typography>

          <Typography :variant="TYPOGRAPHY_TYPES.h2" :class="$style.title">
            <slot name="title" />
          </Typography>

          <Typography
            :variant="TYPOGRAPHY_TYPES.h5"
            :class="$style.contentSlot"
          >
            <slot name="content" />
          </Typography>

          <slot name="button" />

          <Typography
            v-if="$slots.cvg"
            :variant="TYPOGRAPHY_TYPES.bodySmallRegular"
            :class="$style.cgv"
          >
            <slot name="cgv" />
          </Typography>
        </div>
      </div>
    </template>
  </ImageOverlay>
</template>

<script>
import { computed, defineComponent, toRefs } from 'vue-demi'

import useMobile from '@front/common/hooks/useMobile'

import ImageOverlay from './ImageOverlay.vue'
import Typography from './Typography'
import TYPOGRAPHY_TYPES from './typographyTypes'

export default defineComponent({
  components: { ImageOverlay, Typography },
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    desktopImageUrl: {
      type: String,
      default: '',
    },
    fadeAppearance: {
      type: String,
      default: 'left-whitefade',
    },
  },
  setup(props) {
    const { imageUrl, desktopImageUrl, fadeAppearance } = toRefs(props)
    const isMobile = useMobile()

    const defaultFadeAppearance = computed(() => {
      const [_position, fade] = fadeAppearance.value.split('-')

      return isMobile.value ? `top-${fade}` : `left-${fade}`
    })

    const url = computed(() => {
      if (!desktopImageUrl.value) {
        return imageUrl.value
      }

      return isMobile.value ? imageUrl.value : desktopImageUrl.value
    })

    return { TYPOGRAPHY_TYPES, url, defaultFadeAppearance }
  },
})
</script>

<style lang="scss" module>
.wrapper {
  height: 278px;
  overflow: hidden;
  border-radius: 0px;

  @media #{map-get($display-breakpoints, 'm-and-up')} {
    border-radius: 16px;
  }
}

.contentWrapper {
  height: 100%;
  width: 100%;
  padding: $ds-spacing-md;

  &.top-whitefade {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 40.25%,
      rgba(255, 255, 255, 0.6) 74.59%
    );
  }

  &.bottom-whitefade {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.5) 30.25%,
      rgba(255, 255, 255, 0) 50.59%
    );
  }

  &.left-whitefade {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.5) 25.5%,
      rgba(255, 255, 255, 0) 74.59%
    );
  }

  &.left-darkfade {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.2) 25.5%,
      rgba(0, 0, 0, 0) 74.59%
    );

    .title,
    .date,
    .contentSlot,
    .cgv {
      color: white;
    }
  }

  &.top-darkfade {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 40.25%,
      rgba(0, 0, 0, 0.3) 74.59%
    );

    .title,
    .date,
    .contentSlot,
    .cgv {
      color: white;
    }
  }

  @media #{map-get($display-breakpoints, 'm-and-up')} {
    padding: 0px $ds-spacing-xl;
  }
}

.content {
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 100%;

  @media #{map-get($display-breakpoints, 'm-and-up')} {
    justify-content: center;
    width: 505px;
  }
}

.contentSlot {
  text-align: center;
  margin-bottom: $ds-spacing-xs;
  max-width: 90%;
}

.title {
  text-align: center;
}

.cgv {
  margin-top: $ds-spacing-xs;
  text-align: center;
}
</style>
