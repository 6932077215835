import {
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_COUNT,
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_SPECIES,
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_NAME,
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_GENDER,
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_SPAYED,
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_BREED,
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_AGE,
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_ACTIVITY,
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_FATNESS,
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_WEIGHT,
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_REPARTITION,
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_VALIDATE,
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_PARENT,
  SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_LOADER,
} from '@showcase/core/routes-names'

export const countStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_COUNT,
}

export const speciesStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_SPECIES,
}

export const nameStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_NAME,
}

export const spayedStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_SPAYED,
}

export const genderStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_GENDER,
}

export const breedStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_BREED,
}

export const ageStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_AGE,
}

export const activityStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_ACTIVITY,
}

export const fatnessStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_FATNESS,
}

export const weightStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_WEIGHT,
}

export const repartitionStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_REPARTITION,
  isDisplayed(window, redirectUrl) {
    return !redirectUrl
  },
}

export const validateStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_VALIDATE,
}

export const parentStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_PARENT,
}

export const loaderStep = {
  name: SHOWCASE_ROUTE_OLD_PROFILE_BUILDER_LOADER,
}
