<template>
  <BoundingBox
    :id="`popper-bounding-box-${id}`"
    :class="$style.popper"
    :should-show-content="isVisible"
    :with-padding="withPadding"
    arrow-color="#FFFFFF"
    :position="position"
    :limit-ref="limitRef"
    :offset="offset"
    :with-arrow="withArrow"
    :is-content-full-width="isContentFullWidth"
  >
    <template #trigger>
      <Button
        appearance="clean"
        :class="triggerClass"
        :disabled="disabled"
        @click="toggle"
      >
        <slot name="trigger" :is-visible="isVisible" />
      </Button>
    </template>

    <template #content>
      <div
        v-click-outside="vcoConfig"
        :class="[{ [$style.withPadding]: withPadding }, $style.box]"
      >
        <slot name="content" :toggle="toggle" />
      </div>
    </template>
  </BoundingBox>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from 'vue-demi'

import { uuid } from '@front/common/utils'

import { Button } from '..'
import BoundingBox from '../BoundingBox'
import { BOX_BOTTOM_POSITION } from '../BoundingBox/constants'
import { positionValidator } from '../BoundingBox/utils'

export const APPEARANCE_SECONDARY = 'appearance/secondary'

export default defineComponent({
  components: { BoundingBox, Button },
  props: {
    limitRef: {
      type: String,
      default: '',
    },
    withPadding: {
      type: Boolean,
      default: true,
    },
    isContentFullWidth: {
      type: Boolean,
      default: false,
    },
    withArrow: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: BOX_BOTTOM_POSITION,
      validator: positionValidator,
    },
    triggerClass: {
      type: [String, Array],
      default: '',
    },
    offset: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const isVisible = ref(false)
    const id = ref()

    function close() {
      isVisible.value = false
      emit('close')
    }

    function toggle() {
      isVisible.value = !isVisible.value
      emit(isVisible.value ? 'open' : 'close')
    }

    const vcoConfig = {
      handler: close,
      middleware(event) {
        return !event.target.closest(`#popper-bounding-box-${id.value}`)
      },
    }

    onBeforeMount(() => {
      id.value = uuid()
    })

    return { isVisible, toggle, vcoConfig, id }
  },
})
</script>

<style lang="scss" module>
.popper {
  position: relative;
  width: 100%;
}

.box {
  background: white;
  border-radius: $ds-radius-sm;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  overflow: hidden;

  &.withPadding {
    padding: $ds-spacing-sm;
  }
}
</style>
