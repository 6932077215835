<template>
  <component :is="component" />
</template>

<script>
import useGrowthbook from '@front/common/hooks/useGrowthbook'
import { FlowType } from '@front/common/types'
import { computed, defineComponent } from '@vue/composition-api'
import { useVuex } from '@vueblocks/vue-use-vuex'

import Default from './components/default.vue'
import New from './components/new.vue'

export default defineComponent({
  setup() {
    const { useGetters } = useVuex()
    const growthbook = useGrowthbook()

    const { flow } = useGetters({
      flow: 'profileBuilder/getFlow',
    })

    const component = computed(() => {
      if (flow.value !== FlowType.classic) {
        return New
      }

      return growthbook.value?.isOn?.('new-funnel-header') ||
        growthbook.value?.getFeatureValue?.('new-profile-builder', 'old') ===
          'new'
        ? New
        : Default
    })

    return { component }
  },
})
</script>
