<template>
  <div :class="$style.wrapper">
    <input
      :id="id"
      :type="type"
      :name="name"
      :class="$style.input"
      :value="value"
      :checked="checked"
      @change="$emit('change', value)"
    />

    <label
      :for="id"
      :class="[$style.contentWrapper, { [$style.checked]: checked }]"
    >
      <ProductCard
        :class="$style.productCard"
        :title="title"
        :selected="checked"
      >
        <template #image>
          <slot name="image" />
        </template>

        <template #title>
          <slot name="title" />
        </template>

        <template #price>
          <slot name="price" />
        </template>

        <template #subTitle>
          <slot name="subTitle" />
        </template>

        <template #quantity>
          <slot name="quantity" />
        </template>

        <template #content>
          <div>
            <div :class="$style.content">
              <slot name="content" />
            </div>

            <div :class="[$style.box, $style[type]]">
              <img src="./check-tick.svg" :class="$style.checkTick" />
            </div>
          </div>
        </template>
      </ProductCard>
    </label>
  </div>
</template>

<script>
import ProductCard from '../ProductCard.vue'

export default {
  components: { ProductCard },
  props: {
    type: {
      type: String,
      default: 'checkbox',
    },
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return `radio-product-${this._uid}`
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
}

.input {
  position: absolute;
  height: 0px;
  width: 0px;
  visibility: hidden;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cardImage {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 18px;
  margin-bottom: $ds-spacing-md;
}

.title {
  width: 100%;
  max-width: 180px;
}

.subTitle {
  margin-top: 6px;
}

.checkIcon {
  width: 20px;
  height: 20px;
}

.textWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.box {
  position: absolute;
  bottom: 15px;
  right: 15px;
  border-radius: 8px;
  margin-top: auto;
  margin-left: auto;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 2px solid $ds-color-black-borders;

  &.radio {
    border-radius: 100%;
  }
}

.checkTick {
  height: 14px;
  width: 14px;
  display: none;
}

.contentWrapper.checked .box {
  border: 2px solid $ds-color-blue-dark;
}

.contentWrapper.checked .checkTick {
  display: block;
}
</style>
