<template>
  <Container>
    <div :class="$style.wrapper">
      <img
        src="@/assets/android-chrome-512x512.png"
        :class="$style.logo"
        @click="openModal"
      />

      <FunnelProgress :class="$style.funnelProgress" />

      <HomeModal />
    </div>
  </Container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useStore } from '@vueblocks/vue-use-vuex'

import Container from '@showcase/components/Container.vue'

import FunnelProgress from '../../FunnelProgress'
import HomeModal from '../../HomeModal.vue'

export default defineComponent({
  components: { FunnelProgress, Container, HomeModal },
  setup() {
    const store = useStore()

    function openModal() {
      store.dispatch('modal/open', 'exitFunnel')
    }

    return { openModal }
  },
})
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  padding: $ds-spacing-md 0px;
  gap: $ds-spacing-md;
}

.logo {
  cursor: pointer;
  width: 40px;
}

.funnelProgress {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex: 2;
  }
}
</style>
