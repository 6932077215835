<template>
  <div :class="$style.wrapper">
    <TwicImg
      :class="$style.image"
      :src="imageUrl"
      ratio="none"
      :focus="focus"
    />

    <div :class="$style.text">
      <Typography
        :variant="isMobile ? TYPOGRAPHY_TYPES.bodyBold : TYPOGRAPHY_TYPES.h4"
        :class="$style.title"
      >
        <slot name="title" />
      </Typography>

      <Typography
        :variant="
          isMobile
            ? TYPOGRAPHY_TYPES.bodyExtraSmallRegular
            : TYPOGRAPHY_TYPES.bodyRegular
        "
        :class="$style.content"
      >
        <slot name="content" />
      </Typography>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import useMobile from '@front/common/hooks/useMobile'

import TYPOGRAPHY_TYPES from './typographyTypes'

import { Typography } from '.'

export default defineComponent({
  components: { Typography },
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    focus: {
      type: String,
      default: 'auto',
    },
  },
  setup() {
    const isMobile = useMobile()

    return { TYPOGRAPHY_TYPES, isMobile }
  },
})
</script>

<style lang="scss" module>
.wrapper {
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  min-height: 170px;

  @media #{map-get($display-breakpoints, 'm-and-up')} {
    height: auto;
    flex-direction: column;
  }
}

.text {
  display: flex;
  flex-direction: column;
  background: $ds-color-black-background;
  padding: $ds-spacing-md;
  padding-right: $ds-spacing-xs;
  flex-grow: 1;

  @media #{map-get($display-breakpoints, 'm-and-up')} {
    padding: $ds-spacing-md;
    align-items: center;
    text-align: center;
  }
}

.image {
  width: 125px;
  flex-shrink: 0;

  @media #{map-get($display-breakpoints, 'm-and-up')} {
    height: 200px;
    width: 100%;
  }
}

.title {
  margin-bottom: $ds-spacing-xs;

  @media #{map-get($display-breakpoints, 'm-and-up')} {
    width: 100%;
    align-items: center;
    text-align: center;
  }
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: $ds-spacing-xs;
  width: 100%;
}
</style>
