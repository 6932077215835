<template>
  <div :class="$style.wrapper">
    <input
      :id="id"
      :type="type"
      :name="name"
      :class="$style.input"
      :value="value"
      :checked="checked"
      @change="$emit('change', value)"
    />

    <label
      :for="id"
      :class="[$style.contentWrapper, { [$style.isSelected]: checked }]"
    >
      <ProductCard
        v-if="isMobile"
        :class="[$style.productCard, { [$style.isSelected]: checked }]"
        :title="title"
      >
        <template #image>
          <slot name="image" />
        </template>

        <template #title>
          <slot name="title" />
        </template>

        <template #price>
          <slot name="price" />
        </template>

        <template #subTitle>
          <slot name="subTitle" />
        </template>

        <template #quantity>
          <slot name="quantity" />
        </template>

        <template #content>
          <div>
            <div :class="$style.content">
              <slot name="content" />
            </div>

            <RadioIcon
              v-if="type === 'radio'"
              :is-selected="checked"
              :class="$style.icon"
            />

            <CheckboxIcon
              v-if="type === 'checkbox'"
              :is-selected="checked"
              :class="$style.icon"
            />
          </div>
        </template>
      </ProductCard>

      <HorizontalProductCard
        v-if="!isMobile"
        :class="[$style.productCard, { [$style.isSelected]: checked }]"
        :title="title"
      >
        <template #image>
          <slot name="image" />
        </template>

        <template #title>
          <slot name="title" />
        </template>

        <template #price>
          <slot name="price" />
        </template>

        <template #subTitle>
          <slot name="subTitle" />
        </template>

        <template #quantity>
          <slot name="quantity" />
        </template>

        <template #content>
          <div>
            <div :class="$style.content">
              <slot name="content" />
            </div>

            <RadioIcon
              v-if="type === 'radio'"
              :is-selected="checked"
              :class="$style.icon"
            />

            <CheckboxIcon
              v-if="type === 'checkbox'"
              :is-selected="checked"
              :class="$style.icon"
            />
          </div>
        </template>
      </HorizontalProductCard>
    </label>
  </div>
</template>

<script>
import { isMobile } from '@front/common/mixins'
import {
  HorizontalProductCard,
  RadioIcon,
  CheckboxIcon,
  ProductCard,
} from '@front/ui'

export default {
  components: { ProductCard, HorizontalProductCard, RadioIcon, CheckboxIcon },
  mixins: [isMobile],
  props: {
    type: {
      type: String,
      default: 'checkbox',
    },
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return `radio-product-${this._uid}`
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
}

.input {
  position: absolute;
  height: 0px;
  width: 0px;
  visibility: hidden;
}

.productCard:hover {
  cursor: pointer;
  background: $ds-color-blue-medium;

  &::before {
    border-radius: 18px;
    position: absolute;
    content: '';
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    z-index: -1;
    box-shadow: 0px 0px 0px 1px $ds-color-blue-dark;
  }
}

.isSelected.isSelected {
  position: relative;
  background: $ds-color-blue-dark;
  z-index: 0;

  &:hover {
    background: $ds-color-blue-dark;
  }

  &::before {
    border-radius: 18px;
    position: absolute;
    content: '';
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    z-index: -1;
    box-shadow: 0px 0px 0px 1px $ds-color-blue-hover;
  }
}

.cardImage {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 18px;
  margin-bottom: $ds-spacing-md;
}

.title {
  width: 100%;
  max-width: 180px;
}

.subTitle {
  margin-top: 6px;
}

.checkIcon {
  width: 20px;
  height: 20px;
}

.textWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.icon {
  position: absolute;
  bottom: 15px;
  right: 15px;
  margin-top: auto;
  margin-left: auto;
}
</style>
