/* eslint-disable no-param-reassign */
import { GrowthBook } from '@growthbook/growthbook'

import { pushDataLayer } from './data-layer-events'
import { getCookie } from './utils'

let growthBook = null
let isLoading = false

export async function initGrowthBook() {
  try {
    if (typeof window === 'undefined') {
      return null
    }

    if (growthBook || isLoading) {
      return growthBook
    }

    const cookie = getCookie('japhy_ga') || getCookie('_ga')
    const id = cookie?.split('.')?.slice(-2)?.join('.')

    isLoading = true

    growthBook = new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      clientKey:
        process.env.GROWTHBOOK_CLIENT_KEY ||
        process.env.VUE_APP_GROWTHBOOK_CLIENT_KEY,
      enableDevMode: process.env.APP_ENV !== 'production',
      attributes: {
        id,
        url: window.location.href,
      },
      trackingCallback: (experiment, result) => {
        pushDataLayer({
          event: 'experiment_viewed',
          event_category: 'experiment',
          experiment_id: experiment.key,
          variation_id: result.variationId,
        })
      },
    })

    await growthBook.loadFeatures()

    isLoading = false

    return growthBook
  } catch (error) {
    console.error('GrowthBook Vue plugin initialization error', error)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export default {
  async install(Vue) {
    const growthBook = null

    Vue.prototype.$initGrowthBook = initGrowthBook

    if (growthBook) {
      return
    }

    Vue.prototype.$growthBook = await initGrowthBook()
  },
}
