import { CAT, DOG } from '@showcase/helpers/constants'

import {
  speciesStep,
  genderStep,
  breedStep,
  ageStep,
  activityStep,
  fatnessStep,
  weightStep,
  repartitionStep,
  loaderStep,
  allergyStep,
  appetiteStep,
  diseaseStep,
  sensibilityStep,
  validateStep,
  wetfoodStep,
} from './steps'

export default {
  [DOG]: [
    speciesStep,
    breedStep,
    genderStep,
    ageStep,
    activityStep,
    fatnessStep,
    weightStep,
    diseaseStep,
    sensibilityStep,
    allergyStep,
    appetiteStep,
    repartitionStep,
    wetfoodStep,
    validateStep,
    loaderStep,
  ],
  [CAT]: [
    speciesStep,
    breedStep,
    genderStep,
    ageStep,
    activityStep,
    fatnessStep,
    weightStep,
    diseaseStep,
    sensibilityStep,
    allergyStep,
    appetiteStep,
    repartitionStep,
    wetfoodStep,
    validateStep,
    loaderStep,
  ],
}
