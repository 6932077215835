<template>
  <Modal name="wetfood-addition-modal">
    <div :class="$style.wrapper">
      <img
        src="@front/common/assets/dog_bowl_with_background.svg"
        width="50"
        height="50"
      />

      <Typography :variant="TYPOGRAPHY_TYPES.h5" tag="h5" :class="$style.title">
        {{ $i18n('Quelle part de terrine dans votre menu ?') }}
      </Typography>

      <RepartitionBlock
        :class="$style.repartitionBlock"
        :wetfood-ratio="currentRatio"
        @updateRepartition="updateRepartition"
      />

      <Divider />

      <div :class="$style.buttons">
        <Button
          appearance="largeSecondary"
          :class="$style.button"
          @click="$store.dispatch('modal/close', 'wetfood-addition-modal')"
        >
          {{ $i18n('Annuler') }}
        </Button>

        <Button :class="$style.button" @click="validate">
          {{ $i18n('Valider') }}
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from 'vue-demi'

import {
  RECOMMANDED_RATIO,
  WETFOOD_RATIO_VALUES,
} from '@front/common/constants'
import { getRatioFromValue } from '@front/common/utils'
import { Button, Divider, Typography, TYPOGRAPHY_TYPES, Modal } from '@front/ui'

import RepartitionBlock from './RepartitionBlock.vue'

export default defineComponent({
  components: { Modal, RepartitionBlock, Typography, Divider, Button },
  data() {
    return {
      wetfoodRatio: WETFOOD_RATIO_VALUES[RECOMMANDED_RATIO],
    }
  },
  computed: {
    TYPOGRAPHY_TYPES: () => TYPOGRAPHY_TYPES,
    currentRatio() {
      return getRatioFromValue(this.wetfoodRatio)
    },
  },
  methods: {
    updateRepartition(value) {
      this.wetfoodRatio = value
    },
    async validate() {
      this.$store.dispatch('modal/close', 'wetfood-addition-modal')

      await this.$nextTick()

      this.$emit('updateRepartition', this.wetfoodRatio)
    },
  },
})
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
}

.title {
  margin: $ds-spacing-md 0px;
}

.repartitionBlock {
  margin: $ds-spacing-xs auto;

  @media #{map-get($display-breakpoints, 'm-and-up')} {
    margin: $ds-spacing-md auto;
  }
}

.buttons {
  margin-top: $ds-spacing-xs;
  display: flex;
  justify-content: center;
  gap: $ds-spacing-md;

  @media #{map-get($display-breakpoints, 'm-and-up')} {
    margin-top: $ds-spacing-md;
  }
}

.button {
  min-width: auto;
  width: 100%;
}
</style>
